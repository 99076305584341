.home-page {
    background: url('../Assets/background/galaxy_background.jpeg') no-repeat center;
    background-size: cover;
    opacity: .8;
    padding: 40px;
    height: 100%;
    display: flex;
    overflow: scroll;
    
    .main-page-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        max-height: 80%;

        .main-context {
            display: flex;
            max-width: 80%;

            .terminal-container {
                height: 100%;
            }
        }
    }

    .side-bar {
        position: fixed;
        right: 0;
        width: 10%;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    
}

