.menu-context {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 1rem;

    .menu-img {
        img {
            margin: auto;
            width: 100%;
        }
    }

    .menu-name {
        span {
            color: white;
            font-size: 20px;
            font-weight: bolder;
            text-align: center;
        }
    }

    .menu-img,
    .menu-name {
        margin: 0 auto;
        text-align: center;
    }
}
