.terminal-style {
  position: relative;
  height: 95%;

  @media only screen and (max-width: 992px) {
    height: 95%;
    margin-bottom: 50px;
  }

  .header-bar {
    background: #eeeeee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    padding: 5px 10px;

    .header-bar-button-group {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .header-bar-button {
        border: 0;
        border-radius: 50%;
        height: 50%;
        margin-right: 4px;
        width: 50%;

        @media only screen and (max-width: 550px) {
          height: 30%;
        }
      }
    }

    .header-title {
      span {
        font-size: 18px;
        @media only screen and (max-width: 550px) {
          font-size: 12px;
        }
      }
    }
  }

  .terminal-body {
    position: relative;
    background-color: #000000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    width: 100%;
    overflow: auto;

    span {
      color: #fff;
      font-size: 24px;
      padding-bottom: 5px;
      padding-top: 5px;
    }

    .terminal-show-tour {
      margin: 10px;
      width: 10%;
    }

    .context-content {
      color: #ffffff;
      font-size: 24px;
    }

    @media only screen and (max-width: 550px) {
        span{
            font-size: 20px;
        }
        .context-content {
            font-size: 20px;
          }
    }
  }
}
